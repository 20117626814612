<!-- 个人信息 -->
<template>
  <div class="usermsg">
    <van-nav-bar
      title="个人信息"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="center">
      <img class="userimg" :src="imgUrl" alt="" />
    </div>
    <div style="text-align: center; cursor: pointer">
      <van-uploader :after-read="afterRead">
        <div class="ctx">更换头像</div>
      </van-uploader>
    </div>

    <div class="name">
      <van-cell-group>
        <van-field v-model="nickName" label="昵称" placeholder="请输入用户名" />
      </van-cell-group>
    </div>
    <div class="centerbtn">
      <button class="msgbtn" @click="getuserUpdate">保存</button>
    </div>
    <div class="btnxgmm">
      <div class="gopassword" @click="gopassword">修改密码</div>
      <div class="clearlogin" @click="clearlogin">退出登录</div>
    </div>
  </div>
</template>

<script>
import {
  getUserInfo,
  uploadFile,
  userUpdate,
  memberLog,
} from "../../api/login";
import { Toast } from "vant";
export default {
  data() {
    return {
      nickName: "",
      imgUrl: "https://lesson.iapeap.com/images/EmotionalValue/mrtx.png",
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
    };
  },
  created() {
    if (window.localStorage.userId) {
      this.getgetUserInfo();
    } else {
      Toast("未登录");

      setTimeout(() => {
        this.$router.push("/login");
        window.localStorage.clear();
      }, 1000);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    gopassword() {
      this.$router.push("/updatePassWord");
    },
    getgetUserInfo() {
      let data = { userId: window.localStorage.getItem("userId") };
      getUserInfo(data).then((res) => {
        if (res.data.Logo) {
          this.imgUrl = res.data.Logo;
        } else {
          this.imgUrl =
            "https://lesson.iapeap.com/images/EmotionalValue/mrtx.png";
        }

        this.nickName = decodeURIComponent(res.data.Prop15);
      });
    },
    clearlogin() {
      Toast.success("已退出登录");

      setTimeout(() => {
        this.$router.push("/login");
        window.localStorage.clear();
      }, 1000);
    },

    //上传头像
    afterRead(file) {
      let data = file.file;
      console.log(data);

      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "上传中...",
        forbidClick: true,
      });
      uploadFile(data).then((res) => {
        Toast.clear();
        if (res.msg == "图片格式错误！") {
          Toast.fail("图片格式错误");
        } else {
          Toast.success("上传成功");
          this.imgUrl = res.imgUrl;
        }
      });
    },

    // 修改信息
    getuserUpdate() {
      let nickName = encodeURIComponent(this.nickName);
      let data = {
        userId: window.localStorage.getItem("userId"),
        nickName: nickName,
        imgUrl: this.imgUrl,
      };
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "正在保存...",
        forbidClick: true,
      });
      userUpdate(data)
        .then((res) => {
          Toast.success("保存成功");
          setTimeout(() => {
            this.goBack();
          }, 1000);
        })
        .catch((error) => {
          Toast.error("服务器异常");
        });
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "个人信息",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>

<style lang="less">
.navbar {
  background-color: #ededed;
}
.van-nav-bar .van-icon {
  color: #464646;
}
.usermsg {
  .center {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    gap: 80px;
    .userimg {
      width: 144px;
      height: 144px;
      border-radius: 50%;
    }
  }
  .ctx {
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
    height: 18px;
    font-size: 13px;
    font-weight: 300;
    color: #72acf4;
    line-height: 18px;
  }
  .name {
    margin-top: 48px;
    padding: 0 52px;
  }
  .van-cell__title {
    width: 50px;
  }
  .centerbtn {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  .msgbtn {
    cursor: pointer;
    width: 100px;
    height: 40px;
    border-radius: 10px;
    background-color: skyblue;
    border: 0;
    color: #fff;
  }
  .btnxgmm {
    display: flex;
    justify-content: space-around;
    margin-top: 80px;
    .gopassword {
      color: #a09f9f;
    }
    .clearlogin {
      color: #a09f9f;
    }
  }
}
</style>
